export default {
	cinema: {
		address: 'ул. Ломоносова, 81',
		place: null,
		phoneNumbers: ['+7 (8184) 538-111', '+7 (911) 066-60-36'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/kinoseverodvinsk',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: 'https://play.google.com/store/apps/details?id=ru.kinoplan.cinema.kinotsum&hl=ru',
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ru/maps/-/CCWLuMY4',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
